<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Operator Leads</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import operatorLeads from '@/services/operatorLeads'
import { DateTime } from 'luxon'

const columns = [
  {
    _t_id: '4290a70e',
    prop: 'fullName',
    text: 'Name',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'fullName',
    detail: false,
  },

  {
    _t_id: '4290a9d4',
    prop: 'phone',
    text: 'Phone',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'phone',
    detail: false,
  },

  {
    _t_id: '4290ab46',
    prop: 'email',
    text: 'Email',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'email',
    detail: false,
  },

  {
    _t_id: '4290ac2c',
    prop: 'companyName',
    text: 'Company',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'companyName',
    defaultSort: true,
    detail: false,
  },

  {
    _t_id: '4290ad12',
    prop: 'dotNumber',
    text: 'DOT',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'dotNumber',
    detail: false,
  },
  {
    _t_id: '4290ae16',
    prop: 'state',
    text: 'State',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'state',
    defaultHidden: true,
    detail: false,
  },

  {
    _t_id: '4290aede',
    prop: 'time',
    text: 'Date',
    sort: true,
    filter: false,
    type: 'text',
    sortProp: 'time',
    defaultSort: true,
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    detail: false,
  },
  {
    _t_id: '4290a992',
    prop: 'companyId',
    text: 'Company ID',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'companyId',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290a9zz92',
    prop: 'amount',
    text: 'Amount',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'amount',
    detail: false,
  },
  {
    _t_id: '4290axx992',
    prop: 'reason',
    text: 'Reason',
    filterType: 'contains',
    type: 'text',
    sortProp: 'reason',
    detail: false,
  },
  {
    _t_id: '4290b0f0',
    prop: 'financing',
    text: 'Financing',
    sort: true,
    filter: true,
    filterType: 'eq',
    type: '',
    sortProp: 'financing',
    computedText: (item) => (item ? 'True' : 'False'),
    customFilterTabDisplay: (val) => {
      return val === 1 ? 'True' : 'False'
    },
    predefined: [
      {
        text: 'True',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        value: 1,
      },
      {
        text: 'False',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        value: 0,
      },
    ],
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Operator Leads',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        enableStatusFilterToggle: false,
        enableExport: false,
        detailKeyId: 'operatorLeadsId',
        tableId: 'operator_leads_tv_view',
        addNewEnabled: false,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'OperatorLeads',
        action: (params) => operatorLeads.getOperatorLeads(params),
      },
    }
  },
}
</script>
